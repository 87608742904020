import moment from 'moment';
import { iCategoriesApi, iOfferApi, iOffer } from '../interfaces';
import {
  enumOffersCategoryType,
  enumOffersOfferType,
  enumOffersDelivery,
  enumOffersStatus,
  enumOffersVisibility,
  enumOffersDonationType,
} from '../enums';
import { dynamicSort } from './commons';
import Images from '../../assets/images';

/*
 * Given the category array and the type, return the relative category object
 */
export const findMainCategory = (categories: any, categoryType: any): iCategoriesApi[] => {
  if (!categories) {
    return [];
  }
  let foundedCategory = categories.filter(
    (category: iCategoriesApi) => category.type === categoryType,
  );

  foundedCategory = foundedCategory.map((i: iCategoriesApi) => {
    const newValue = { ...i };
    const sub = [...(newValue.subCategories || [])];
    newValue.subCategories = sub.sort(dynamicSort('name'));
    return newValue;
  });

  return foundedCategory.sort(dynamicSort('name'));
};

/*
 * Given the category array, return the object of the selected category
 */
export const renderCategoryObject = (
  mainCategories: any,
  selectedCategory: any,
): iCategoriesApi => {
  let mainCat = null;
  if (selectedCategory) {
    const { key } = selectedCategory;
    mainCat = mainCategories.find((cat: any) => cat.id === key);
  }
  return mainCat;
};

export const offersCategoryTypeSource = [
  { value: enumOffersCategoryType.food, label: 'Food' },
  { value: enumOffersCategoryType.nonfood, label: 'Non-Food' },
  // { value: enumOffersCategoryType.service, label: 'Servizi' },
  { value: enumOffersCategoryType.medicine, label: 'Farmaci' },
];

export const offersTypeSource = [
  { value: enumOffersOfferType.donation, label: 'Donazioni' },
  { value: enumOffersOfferType.sale, label: 'Con Crediti' },
];

export const offersDonationTypeSource = [
  { value: enumOffersDonationType.riskOfWaste, label: 'A rischio spreco' },
  { value: enumOffersDonationType.voluntaryDonation, label: 'Donazione volontaria' },
  { value: enumOffersDonationType.returned, label: 'Reso' },
  { value: enumOffersDonationType.slowMovers, label: 'Slow movers' },
  { value: enumOffersDonationType.obsolete, label: 'Obsoleti' },
  { value: enumOffersDonationType.defective, label: 'Difettosi' },
];

export const offersDeliverySource = [
  { value: enumOffersDelivery.shipment, label: 'Spedizione' },
  { value: enumOffersDelivery.collection, label: 'Ritiro' },
];

export const offersStatusSource = [
  { value: enumOffersStatus.withorders, label: 'Con prenotazioni' },
  { value: enumOffersStatus.completed, label: 'Completate' },
  { value: enumOffersStatus.noorders, label: 'Senza prenotazioni' },
];

export const offersPackaging = [
  'Pacco',
  'Bottiglia',
  'Scatoletta',
  'Vasetto',
  'Cartone',
  'Barile',
  'Fusto',
  'Sacchetto',
  'Sacco',
  'Lattina',
  'Latta',
  'Fardello',
  'Pezzi',
  'Cassetta',
  'Flacone',
  'Pallet',
  'Tubetto',
  'Busta',
  'Confezione',
  'Vaschetta',
  'Panetto',
  'Brick',
  'Barattolo',
  'Tetrapack',
  'Box',
  'Bag',
];

export const offersEmptyApi: iOfferApi = {
  categoryType: '',
  categoryId: '',
  subCategoryId: '',
  packagingType: '',
  unitAmount: null,
  measureId: null,
  tags: [],
  productCode: '',
  type: enumOffersOfferType.donation,
  unitCost: null,
  expiryType: '',
  expiryDate: null,
  totalUnits: null,
  minUnits: null,
  unitSize: null,
  unitWeight: null,
  deliveryType: [enumOffersDelivery.collection],
  shipmentCost: null,
  endDate: null,
  notes: '',
  visibility: enumOffersVisibility.public,
  typeDonation: null,
  visibilityNoProfits: [],
};

export function mapDetailToOffersEmptyApi(detail: any): any {
  const data = {
    categoryType: detail.categoryType,
    categoryId: detail.categoryId,
    subCategoryId: detail.subCategoryId,
    packagingType: detail.packagingType,
    unitAmount: detail.unitAmount,
    measureId: detail.measureId,
    tags: detail.tags,
    productCode: detail.productCode,
    type: detail.type,
    unitCost: detail.unitCost,
    expiryType: detail.expiryType,
    expiryDate: detail.expiryDate ? moment(detail.expiryDate) : '',
    totalUnits: detail.totalUnits,
    minUnits: detail.minUnits,
    unitSize: detail.unitSize ? detail.unitSize.join('-') : '',
    unitWeight: detail.unitWeight,
    deliveryType: detail.deliveryType,
    shipmentCost: detail.shipmentCost,
    endDate: detail.endDate ? moment(detail.endDate) : '',
    notes: detail.notes,
    visibility: detail.visibility,
    typeDonation: detail.typeDonation,
    visibilityNoProfits: detail.visibilityNoProfits,
  };

  console.log(123, data);
  return data;
}

export function decodeOfferCredits(data: any): any {
  const encoded: iOffer = { ...data };
  const { unitCost, shipmentCost } = data;
  if (unitCost) {
    encoded.unitCost = Number(unitCost) / 100;
  }
  if (shipmentCost) {
    encoded.shipmentCost = Number(shipmentCost) / 100;
  }
  return encoded;
}

export function decodeCredits(credits: number): number {
  return Number(credits) / 100;
}

export function encodeCredits(credits: number): number {
  return Number((credits * 100).toFixed(2));
}

export function mapOfferWithOrder(items: any[]): iOffer[] {
  if (!items || !items.length) {
    return [];
  }

  let offerItem = null;
  let order = null;
  const list = items.map((i: any) => {
    if (!i.offer) {
      const data = decodeOfferCredits(i);
      return data;
    }
    order = { ...i };
    offerItem = decodeOfferCredits(i.offer);
    delete order.offer;
    offerItem.order = order;
    offerItem.info = order.info;
    return offerItem;
  });

  return list;
}

export const getSecondValueUnit = (info: any, availableUnits: number, totalUnits: number): any => {
  if (info.acceptedOrders === 0) {
    if (info.openOrders > 0) {
      return undefined;
    }
  }

  if (availableUnits !== totalUnits) {
    return totalUnits.toLocaleString();
  }
  return undefined;
};

export const renderIcon = (offer: iOfferApi) => {
  const { categoryType } = offer;
  switch (categoryType) {
    case enumOffersCategoryType.food:
      return Images.icoFood;
    case enumOffersCategoryType.nonfood:
      return Images.icoNonFood;
    case enumOffersCategoryType.service:
      return Images.icoService;
    case enumOffersCategoryType.medicine:
      return Images.icoMedicine;
    default:
      return Images.icoAvatar;
  }
};

// DEV_NOTE Possibile criticità.
// I calcoli per i pesi vengono replicati sia BE che FE
// Oltre ai parametri ricevuti ci sono logiche replicate
// Es: measure.type === 'weight', subCategory.convLtToKg || 1 divisione per 1000
export function getTotOrderWeight(offer: iOffer, units: number): number {
  const { unitAmount, measure, subCategory } = offer;
  const categoryConversion = subCategory.convLtToKg || 1;
  const conversion = measure.type === 'weight' ? 1 : 1000 * categoryConversion;
  return unitAmount
    ? Number(((((units * unitAmount) / measure.coefficient) * conversion) / 1000).toFixed(2))
    : 0;
}

// export const distanceLatLng = (p1: number[], p2: number[]) => {
//   if (p1[0] === p2[0] && p1[1] === p2[1]) {
//     return 0;
//   }
//   const radlat1 = (Math.PI * p1[0]) / 180;
//   const radlat2 = (Math.PI * p2[0]) / 180;
//   const theta = p1[1] - p2[1];
//   const radtheta = (Math.PI * theta) / 180;
//   let dist =
//     Math.sin(radlat1) * Math.sin(radlat2) +
//     Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
//   if (dist > 1) {
//     dist = 1;
//   }
//   return dist;
// };

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export function distanceLatLng(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}
